<template>
  <div>
    <v-form v-model="valid" ref="form">
      <div class="tway-header-2 mb-6">Nombres</div>
      <div class="input-label ml-2">Nombre del proyecto</div>
      <v-text-field
        v-model="formData.name"
        :rules="[required('Nombre del proyecto'), validateMaxLength(50)]"
        class="mb-4 roboto"
        type="text"
        maxlength="50"
        counter
        :counter-value="formatCounter(50)"
        placeholder="Escribe el nombre del proyecto"
        outlined
      />
      <div class="input-label ml-2 ml-2">Responsable</div>
      <v-text-field
        v-model="formData.owner"
        :rules="[required('Responsable'), validateMaxLength(50)]"
        counter
        :counter-value="formatCounter(50)"
        maxlength="50"
        class="mb-4 roboto"
        type="text"
        placeholder="Responsable"
        outlined
      />
      <v-divider class="mb-4" />
      <div class="tway-header-2 mb-6">Resumen proyecto</div>
      <div class="input-label ml-2">Brecha a acortar</div>
      <v-textarea
        v-model="formData.summary"
        :rules="[required('Brecha a acortar'), validateMaxLength(255)]"
        counter
        maxlength="255"
        :counter-value="formatCounter(255)"
        class="mb-3 roboto"
        name="input-7-1"
        placeholder="Escribe la brecha a acortar"
        outlined
        no-resize
      />
      <div class="input-label ml-2">Objetivos de negocio</div>
      <v-textarea
        v-model="formData.businessGoal"
        :rules="[required('Objetivos de negocio'), validateMaxLength(255)]"
        counter
        maxlength="255"
        :counter-value="formatCounter(255)"
        class="mb-3 roboto"
        name="input-7-1"
        placeholder="Breve descripción del objetivo de negocio"
        outlined
        no-resize
      />
      <div class="input-label ml-2">Objetivos del proyecto</div>
      <v-textarea
        v-model="formData.proyectGoal"
        :rules="[required('Objetivos del proyecto'), validateMaxLength(255)]"
        counter
        maxlength="255"
        :counter-value="formatCounter(255)"
        class="mb-3 roboto"
        name="input-7-1"
        placeholder="Breve descripción de objetivos del proyecto"
        outlined
        no-resize
      />
      <div class="input-label ml-2">¿Cuál es el alcance del proyecto?</div>
      <v-textarea
        v-model="formData.scope"
        :rules="[required('Alcance del proyecto'), validateMaxLength(255)]"
        counter
        maxlength="255"
        :counter-value="formatCounter(255)"
        class="mb-3 roboto"
        name="input-7-1"
        placeholder="Breve descripción del alcance"
        outlined
        no-resize
      />
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ValidatorsService from "@/services/validatorsService";

export default {
  mounted() {
    if (this.stepIsComplete(this.stepIndex)) {
      let stepData = this.getStep(this.stepIndex);
      this.formData = stepData.data;
    } else {
      this.setInProgress(this.stepIndex);
    }
  },
  updated() {
    this.setComplete(this.stepIndex);
    this.setStepData({
      index: this.stepIndex,
      data: { ...this.formData },
    });
  },
  name: "GeneralData",
  data() {
    return {
      stepIndex: 0,
      valid: false,

      formData: {
        name: "",
        owner: "",
        summary: "",
        businessGoal: "",
        proyectGoal: "",
        scope: "",
      },
    };
  },
  methods: {
    ...mapActions("createProject", ["setInProgress", "setComplete", "setIncomplete", "setStepData", "resetStep"]),
    formatCounter: (max) => (text) => max - text.length,
    required: (propertyType) => ValidatorsService.required(propertyType),
    validateMaxLength: (max) => ValidatorsService.validateMaxLength(max),
  },
  computed: {
    ...mapGetters({
      stepIsComplete: "createProject/getStepIsComplete",
      getStep: "createProject/getStep",
    }),
  },
};
</script>

<style scoped>
.input-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
}
.v-application .primary--text {
  caret-color: #7319d5;
  color: #7319d5 !important;
}
</style>
